@media (max-width: 767px) {
  .mobile-font-size {
    font-size: 12.6667px;
  }
}
@media (max-width: 767px) {
  .welcome-note {
    font-size: 14.6667px;
  }
}
